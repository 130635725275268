<template>
  <div>
    <v-dialog
      v-model="d_prev"
      @click:outside="$emit('close')"
      @keydown.esc="$emit('close')"
      max-width="100%"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5">
          <h4>Preview Invoice</h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="inventory">
          <div class="d-flex justify-space-between align-center mb-3">
            <h4 style="flex-grow: 2">Invoice Psikotes</h4>
            <div class="d-flex">
              <h4 class="mr-2">Total Tagihan</h4>
              <h4>
                Rp.
                {{
                  inventory.preview.total
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
              </h4>
            </div>
          </div>
          <v-row>
            <v-col cols="12" md="6">
              <h4 class="not_bold">Nama Pelanggan</h4>
              <h4>
                {{ inventory.preview.client }}
              </h4>
            </v-col>
            <v-col cols="12" md="6">
              <h4 class="not_bold">ID Transaksi</h4>
              <h4>
                {{ inventory.preview.id }}
              </h4>
            </v-col>
            <v-col cols="12" md="6">
              <h4 class="not_bold">Tanggal Transaksi</h4>
              <h4>
                {{ $date(inventory.preview.trx).format("DD/MM/YYYY") }}
              </h4>
            </v-col>
            <v-col cols="12" md="6">
              <h4 class="not_bold">Tanggal Jatuh Tempo</h4>
              <h4>
                {{ $date(inventory.preview.due).format("DD/MM/YYYY") }}
              </h4>
            </v-col>
          </v-row>

          <v-simple-table
            class="mt-4 pb-2 rounded"
            style="overflow: hidden; border: 1px solid grey"
          >
            <template v-slot:default>
              <thead style="background: #052633">
                <tr>
                  <th class="text-left" style="color: white">Layanan</th>
                  <th class="text-left" style="color: white">Kuantitas</th>
                  <th class="text-left" style="color: white">Harga Satuan</th>
                  <th class="text-left" style="color: white">Diskon</th>
                  <th class="text-left" style="color: white">Pajak</th>
                  <th class="text-left" style="color: white">Jumlah</th>
                </tr>
              </thead>
              <tbody style="background: white">
                <tr v-for="(item, n) in inventory.preview.data" :key="n">
                  <td>
                    <h4 class="text-capitalize">{{ item.name }}</h4>
                  </td>

                  <td>
                    <h4 class="text-capitalize">
                      {{ item.qty }}
                    </h4>
                  </td>
                  <td>
                    <h4 style="width: 100px" class="text-capitalize">
                      Rp.
                      {{
                        item.cost
                          ? item.cost
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                          : item.cost
                      }}
                    </h4>
                  </td>
                  <td>
                    <h4 class="text-capitalize">
                      Rp.
                      {{
                        item.diskon
                          ? item.diskon
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                          : item.diskon
                      }}
                    </h4>
                  </td>
                  <td>
                    <v-select
                      style="max-width: 120px"
                      class="my-2 rounded"
                      placeholder="Isi pajak"
                      hide-details
                      readonly
                      :items="optPajak"
                      item-text="text"
                      item-value="value"
                      :value="item.pajak"
                      dense
                      solo
                      flat
                      background-color="transparent"
                      append-icon=""
                    ></v-select>
                  </td>
                  <td>
                    <h4 class="text-capitalize">
                      Rp.
                      {{
                        item.jumlah
                          ? item.jumlah
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                          : item.jumlah
                      }}
                    </h4>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row>
            <v-col cols="12" md="6">
              <h4 class="not_bold">Notes</h4>
              <v-card outlined class="pa-2">
                <p class="mb-0 smals_txt">{{ inventory.preview.notes }}</p>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <div class="d-flex justify-space-between">
                <h4>Subtotal</h4>
                <h4>
                  Rp.
                  {{
                    inventory.preview.sub
                      ? inventory.preview.sub
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      : inventory.preview.sub
                  }}
                </h4>
              </div>
              <div class="d-flex justify-space-between">
                <h4>PPN</h4>
                <h4>
                  Rp.
                  {{
                    inventory.preview.ppn
                      ? inventory.preview.ppn
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      : inventory.preview.ppn
                  }}
                </h4>
              </div>
              <v-divider class="my-4"></v-divider>
              <div class="mt-2 d-flex justify-space-between">
                <h4>Total Invoice</h4>
                <h4>
                  Rp.
                  {{
                    inventory.preview.total
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  }}
                </h4>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            outlined
            color="#BD9028"
            class="text-capitalize"
            @click="$emit('close')"
          >
            Cancel
          </v-btn>

          <v-btn
            v-if="!loadingCreate"
            color="#052633"
            @click="generateInv"
            dark
            class="text-capitalize"
          >
            Generate Invoice
          </v-btn>
          <v-btn
            v-if="loadingCreate"
            color="#052633"
            dark
            class="text-capitalize"
          >
            Generating...
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "previewInv",
  props: ["d_prev", "inventory"],
  data() {
    return {
      loadingCreate: false,
      optPajak: [
        { text: "PPN", value: 11 },
        { text: "Kosong", value: 0 },
      ],
    };
  },
  methods: {
    generateInv() {
      this.loadingCreate = true;
      let data = {
        body: this.inventory.body,
        path: this.inventory.path,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loadingCreate = false;
          if (data.status != "success") {
            if (data.data) {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            } else {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
          } else {
            this.loadingCreate = false;
            this.$router.push(
              `/admin/mku/invoice/detail/${data.data[0].invoice_manual_id}`
            );

            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          }
        })
        .catch((e) => {
          this.loadingCreate = false;
          if (e.data) {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          } else {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.v-data-table tbody tr:nth-child(even) {
  background-color: white !important;
}
</style>